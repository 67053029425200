@import "./src/styles/mixins";
@import "./src/styles/fonts";

.UserDonorClass {
  padding: 8px 0;
  white-space: nowrap;
  margin-top: 4px;

  & > span {
    padding: 8px;
    border-radius: 5px;
    background-color: $Donor_potential;
    @extend .Caption_status_label;
  }
}
